import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Item, Message, Modal, Search } from 'semantic-ui-react';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';

import { EditableVistObject } from '../../types';
import { Api } from '@plone/volto/helpers';
import { DatetimeWidget } from '@plone/volto/components';

import config from '@plone/volto/registry';

export default class VistEditDialog extends Component {
  static propTypes = {
    vistObject: EditableVistObject,
    onSaved: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      saving: false,
      error: undefined,
      selectedImage: props.vistObject.image,
      selectedImageUUID: props.vistObject.image_uuid,
      selectedAktuell: props.vistObject.aktuell ? 1 : 0,
      aktuellExpire: props.vistObject.aktuell_expire * 1000,
      imageSearchLoading: false,
      imageSearchResults: [],
      imageSearchValue: undefined,
    };
    this.api = new Api();
    this.handleOpen = this.handleOpen.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChangeAktuell = this.handleChangeAktuell.bind(this);
    this.handleChangeAktuellExpire = this.handleChangeAktuellExpire.bind(this);
    this.handleImageSearchChange = this.handleImageSearchChange.bind(this);
    this.handleImageResultSelect = this.handleImageResultSelect.bind(this);
    this.reset = this.reset.bind(this);
    this.resetImageSearch = this.resetImageSearch.bind(this);
  }

  componentDidMount() {}

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.vistObject !== nextProps.vistObject) {
      this.reset(nextProps.vistObject);
    }
  }

  handleOpen() {
    this.setState({ open: true });
  }

  handleCancel(event) {
    this.setState({ open: false });
    this.reset();
  }

  handleSave(event) {
    this.setState({ saving: true });
    let data = {
      image_uuid: this.state.selectedImageUUID,
      aktuell: this.state.selectedAktuell,
      aktuell_expire: this.state.aktuellExpire,
    };

    let path = this.props.vistObject['@id'].replace(
      config.settings.apiPath,
      '',
    );
    this.api
      .patch(path, {
        data: data,
      })
      .then(
        (result) => {
          this.setState({
            saving: false,
            error: false,
          });
          this.props.onSaved(result);
          this.handleCancel();
        },
        (error) => {
          this.setState({
            saving: false,
            error: true,
          });
        },
      );
    this.props.onSaved();
  }

  handleChangeAktuell(event, data) {
    this.setState({
      selectedAktuell: data.checked ? 1 : 0,
    });
  }

  handleChangeAktuellExpire(event, data) {
    if (data) {
      this.setState({
        aktuellExpire: new Date(data).getTime(),
      });
    } else {
      this.setState({
        aktuellExpire: 0,
      });
    }
  }

  reset(vistObject) {
    vistObject = vistObject || this.props.vistObject;
    this.setState({
      selectedImage: vistObject.image,
      selectedImageUUID: vistObject.image_uuid,
      selectedAktuell: vistObject.aktuell,
      aktuellExpire: vistObject.aktuell_expire * 1000,
      imageSearchValue: vistObject.image ? vistObject.image.title : '',
    });
    this.resetImageSearch();
  }

  resetImageSearch() {
    this.setState({
      imageSearchLoading: false,
      imageSearchResults: [],
      imageSearchValue: '',
    });
  }

  handleImageResultSelect(e, { result }) {
    let image = { ...result };
    image['@id'] = result.id;
    this.setState({
      imageSearchValue: result.title,
      selectedImage: image,
      selectedImageUUID: result.image_uuid,
    });
  }

  handleImageSearchChange(e, { value }) {
    /* Cancel old requests so we display only results matching
    the current search term. */
    if (this.promise) {
      this.promise.request.abort();
      this.promise = undefined;
    }
    this.setState({
      imageSearchLoading: true,
      imageSearchValue: value,
    });
    const promise = this.api.get('/@search', {
      params: {
        portal_type: 'Image',
        SearchableText: value,
        fullobjects: 1,
        b_size: 50,
      },
    });
    this.promise = promise;
    promise.then(
      (result) => {
        let cleanedUpItems = [];
        // eslint-disable-next-line array-callback-return
        result.items.map((item) => {
          cleanedUpItems.push({
            image: `${item['@id']}/@@images/image/thumb`,
            title: item.title,
            id: item['@id'],
            image_uuid: item.UID,
          });
        });
        this.setState({
          imageSearchLoading: false,
          imageSearchResults: cleanedUpItems,
          error: false,
        });
      },
      (error) => {
        this.setState({
          imageSearchLoading: false,
          error: true,
        });
      },
    );
  }

  render() {
    if (!this.props.vistObject.can_edit) {
      return '';
    }

    return (
      <div className="edit-vist-object">
        <Button className="edit-vist.object-open" onClick={this.handleOpen}>
          Bearbeiten
        </Button>
        {!this.props.hasLocalPDF && (
          <Link
            to={`${config.settings.pd_path}/add?type=File&Title=${this.props.vistObject.productcode}`}
            className="ui button"
          >
            PDF hochladen
          </Link>
        )}
        <Modal
          className="edit-vist-object-modal"
          size="small"
          open={this.state.open}
          onOpen={this.reset}
          onClose={this.handleCancel}
          closeOnDimmerClick={false}
        >
          <Modal.Header
            content={`„${this.props.vistObject.name}“ bearbeiten`}
          />
          <Modal.Content>
            <Form
              submit={this.handleSaveRecipe}
              loading={this.saving}
              error={this.state.error}
            >
              <Message error>Es ist ein Fehler aufgetreten.</Message>

              {this.state.selectedImage ? (
                <Item>
                  <Item.Image
                    src={`${this.state.selectedImage['@id']}/@@images/image/thumb`}
                  />
                </Item>
              ) : (
                ''
              )}
              <Form.Field>
                <Search
                  loading={this.state.imageSearchLoading}
                  onResultSelect={this.handleImageResultSelect}
                  onSearchChange={debounce(this.handleImageSearchChange, 500, {
                    leading: true,
                  })}
                  results={this.state.imageSearchResults}
                  value={this.state.imageSearchValue}
                />
              </Form.Field>
              <Form.Field>
                <Form.Checkbox
                  label="Dauerhaft aktuell"
                  name="aktuell"
                  onChange={this.handleChangeAktuell}
                  checked={this.state.selectedAktuell === 1 ? true : false}
                />
              </Form.Field>
              <Form.Field
                disabled={this.state.selectedAktuell === 1 ? true : false}
              >
                <DatetimeWidget
                  title="Aktuell bis:"
                  dateOnly
                  onChange={this.handleChangeAktuellExpire}
                  noPastDates
                  value={
                    this.state.aktuellExpire &&
                    new Date(this.state.aktuellExpire).toISOString()
                  }
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleCancel}>Cancel</Button>
            <Button onClick={this.handleSave}>Save</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
